import { offers } from '@shared/offers';


window.Rally = window.Rally || {};
window.RallyCheckoutData = window.RallyCheckoutData || {};
const resourcesUrl = ENV_RESOURCES_URL;
let rallyScript = document.createElement('script');
rallyScript.async = true;
rallyScript.src = `https://${resourcesUrl}/elements/elements.js`;
let firstExistingScriptEl = document.getElementsByTagName('script')[0];
firstExistingScriptEl.parentNode.insertBefore(rallyScript, firstExistingScriptEl);

window.Rally.platform = window.Rally.platform || {};

window.Rally.platform.getCart = () => {
  return fetch('/api/storefront/cart?include=lineItems.digitalItems.options,lineItems.physicalItems.options', { credentials: 'same-origin' })
    .then((cart) => cart.json())
    .then((cart) => {
      if (cart && cart.length > 0) {
        return fetch('/api/storefront/checkout/' + cart[0].id + '?include=payments,customer,consignments.availableShippingOptions,consignments.selectedShippingOption,cart.lineItems.physicalItems.options,cart.lineItems.digitalItems.options,promotions,customer.customerGroup', { credentials: 'same-origin' })
          .then((checkoutSession) => checkoutSession.json())
          .then((checkoutSession) => {
            const checkoutSessionCart = checkoutSession.cart;
            const customerData = checkoutSession.customer ? getCustomerData(checkoutSession.customer) : null;
            window.RallyCheckoutData.id = checkoutSessionCart.id;
            window.RallyCheckoutData.currency = checkoutSessionCart.currency?.code;
            window.RallyCheckoutData.content = cart;
            if (!window.Rally.platform.refresh) {
              window.RallyCheckoutData.refresh = () => {
                try {
                  const reactContainer = document.querySelector('#checkout-app');
                  const reactContainerKey = Object.keys(reactContainer).find(key => key.includes('reactContainer'));
                  const service = reactContainer[reactContainerKey].child.stateNode.checkoutService;
                  service.loadCheckout();
                } catch (e) {
                  console.log(e);
                }
              };
            } else {
              window.RallyCheckoutData.refresh = window.Rally.platform.refresh;
            }
            return { id: checkoutSessionCart.id, currency: checkoutSessionCart.currency.code, content: checkoutSession, customerData: customerData };
          });
      } else {
        return null;
      }
    });
};

const handleConfiguration = () => {
  try {
    const bcStylesheet = document.createElement('style');
    bcStylesheet.type = 'text/css';
    const maxWidth = window.Rally?.platform?.orderBumpConfig?.mobileHookMaxWidth || 969;
    bcStylesheet.innerHTML = `@media (max-width: ${maxWidth}px) { 
      .layout-main rally-offer-list.rally-order-bump-mobile { 
        margin-bottom: 1.5rem;
        border-bottom: 1px solid var(--border-color);
        display: block;
      }
      .checkout-step.checkout-step--customer {
        border-bottom: none !important;
      }
    }`;
    document.head.appendChild(bcStylesheet);
    const merchantConfiguration = document.createElement('script');
    merchantConfiguration.src = `https://${ENV_RESOURCES_URL}/organization-scripts/${window.Rally.platform.clientId}/offers-only.js`;
    document.head.appendChild(merchantConfiguration);
  } catch (e) { }

  if (!window.Rally.platform.postPurchaseOfferConfig) {
    window.Rally.platform.postPurchaseOfferConfig = {}
  }
  if (!window.Rally.platform.orderBumpConfig) {
    window.Rally.platform.orderBumpConfig = {};
  }

  if (!window.Rally.platform.orderBumpConfig.verticalDesignMaxWidth) {
    window.Rally.platform.orderBumpConfig.verticalDesignMaxWidth = 1100;
  }

  if (!window.Rally.platform.orderBumpConfig.mobileHookMaxWidth) {
    window.Rally.platform.orderBumpConfig.mobileHookMaxWidth = 969;
  }

  if (!window.Rally.platform.orderBumpConfig.hideOnElementPresence) {
    window.Rally.platform.orderBumpConfig.hideOnElementPresence = {
      observedElHook: '.checkout-step--payment',
      presentElHook: '.checkout-step--payment .checkout-view-content'
    };
  }

  if (!window.Rally.platform.orderBumpConfig.backgroundColor) {
    window.Rally.platform.orderBumpConfig.backgroundColor = 'white';
  }

  if (!window.Rally.platform.postPurchaseOfferConfig.getPlaceOrderElement) {
    window.Rally.platform.postPurchaseOfferConfig.getPlaceOrderElement = () => { return null; }
  }

  if (!window.Rally.platform.orderBumpConfig.getHookElement) {
    window.Rally.platform.orderBumpConfig.getHookElement = () => { return document.querySelector(window.Rally.platform.orderBumpConfig.hookSelector || '.optimizedCheckout-orderSummary'); }
  }

  if (!window.Rally.platform.orderBumpConfig.getMobileHookElement) {
    window.Rally.platform.orderBumpConfig.getMobileHookElement = () => { return document.querySelector(window.Rally.platform.orderBumpConfig.mobileHookSelector || '.checkout-step--customer'); }
  }
}

const initializeRally = (config) => {
  if (window.Rally.init) {
    window.Rally.init(window.Rally.platform.clientId, config);
  } else {
    rallyScript.onload = () => {
      window.Rally.init(window.Rally.platform.clientId, config);
    };
  }
}

const getConfig = () => {
  return { cartData: { id: window.RallyCheckoutData.id, currency: window.RallyCheckoutData.currency, content: window.RallyCheckoutData.content } };
}

const handleRallyInit = (cartData) => {
  if (window.Rally.platform.product === 'RALLY_OFFERS') {
    handleConfiguration();

    const offersConfig = {
      pageType: "checkout", flowSegments: ['other'], sessionOrigin: 'offers_only',
      includeElements: ['rally-offer-accept', 'rally-offer-decline', 'rally-order-summary', 'rally-offer-list', 'rally-modal', 'rally-shipping-options']
    };
    const config = { ...getConfig(), ...offersConfig };
    initializeRally(config);
    offers.init();
  } else {
    window.Rally.platform.config = window.Rally.platform.config || {};
    window.Rally.platform.init = window.Rally.platform.init || (() => { });
    if (cartData) {
      window.Rally.platform.init();
      let lineItems = cartData.content.cart?.lineItems;
      let shouldForceFallback = lineItems && lineItems.physicalItems && lineItems.physicalItems.length === 0;
      window.Rally.platform.config.forceFallback = shouldForceFallback;
      window.Rally.platform.config.cartData = cartData;
    }
    window.Rally.platform.config.flowSegments = ['storefront'];
    initializeRally({ ...getConfig(), ...window.Rally.platform.config });
  }
}

const getCustomerData = (customer) => {
  const shippingAddress = customer.addresses && customer.addresses.length ? customer.addresses[0] : {};
  const phoneNumber = shippingAddress?.countryCode === 'US' && shippingAddress.phone ? shippingAddress.phone.substr(shippingAddress.phone.length - 10) : shippingAddress.phone;

  const customerData = {
    email: customer.email,
    firstName: customer.firstName || shippingAddress.firstName,
    lastName: customer.lastName || shippingAddress.lastName,
    phone: phoneNumber,
    shipping: {
      externalId: shippingAddress.id,
      firstName: shippingAddress.firstName || customer.firstName,
      lastName: shippingAddress.lastName || customer.lastName,
      company: shippingAddress.company,
      address1: shippingAddress.address1,
      address2: shippingAddress.address2,
      city: shippingAddress.city,
      country: shippingAddress.country,
      countryCode: shippingAddress.countryCode,
      province: shippingAddress.stateOrProvince,
      zip: shippingAddress.postalCode,
      phone: phoneNumber,
    }
  }

  if (customer.customerGroup?.name) {
    customerData['customerGroup'] = customer.customerGroup.name;
  }
  return customerData;
}

window.Rally.platform.getCart().then((cartData) => handleRallyInit(cartData));


