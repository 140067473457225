const createOffersService = () => {
    let isRallyPostPurchaseInitialized = false;
    let isRallyOrderBumpsInitialized = false;
    let isCompleteCheckoutInitiated = false;
    let isPayNowClickInProgress = false;
    let initialShippingOptions;
    let selectedShippingOptionId: string;
    let mainOfferModal: HTMLElement;
    const handlePPO = (activeOffer) => {
        let isFullPageOffer = activeOffer?.action !== 'modal';
        mainOfferModal = document.createElement('rally-modal');
        const url = `${activeOffer.url}?mode=offers_only`;
        mainOfferModal.setAttribute('config', '{"closableOnBackdropClick": false, "modalDialogClass": "rally-modal-dialog rally-modal-dialog-scrollable rally-offer-modal-dialog"}')
        mainOfferModal.innerHTML = `
            <iframe id="offerIframe" src=${url} frameborder="0"></iframe>
            `;
        mainOfferModal.classList.toggle('rally-full-page-offer', isFullPageOffer);
        document.body.appendChild(mainOfferModal);
        isPayNowClickInProgress = false;
        window.addEventListener('message', (event) => {
            const data = event.data;
            const iframeElement: HTMLIFrameElement = document.getElementById('offerIframe') as HTMLIFrameElement;
            if (data?.eventType === 'customFlow.updated' || data?.eventType === 'page.error') {
                isFullPageOffer = data?.action ? data?.action !== 'modal' : activeOffer?.action !== 'modal';
                if (data?.nextPageUrl && data?.type !== 'confirmation' && iframeElement) {
                    const nextPageUrl = `${data.nextPageUrl}?mode=offers_only`;
                    iframeElement.src = nextPageUrl;
                    mainOfferModal.classList.add('rally-new-offer-loading');
                    // Updating the modal after the previous offer is unloaded to smooth the transition
                    setTimeout(() => {
                        mainOfferModal.classList.toggle('rally-full-page-offer', isFullPageOffer);
                        mainOfferModal.classList.remove('rally-new-offer-loading');
                    }, 300);
                } else if (data?.nextPageUrl) {
                    const finishFlow = (shippingModal?: HTMLElement) => {
                        isCompleteCheckoutInitiated = true;
                        const nativePurchaseButton = window.Rally.platform.postPurchaseOfferConfig?.getPlaceOrderElement();
                        nativePurchaseButton?.click();
                        nativePurchaseButton?.classList.remove('rally-d-none');
                        document.getElementById('rallyPlaceOrder')?.classList.add('rally-d-none');
                        setTimeout(() => {
                            RallyCheckoutData.refresh(() => {
                                shippingModal?.remove();
                                mainOfferModal.remove();
                            });
                        }, 2500);
                    };
                    window.Rally.data.refresh(() => {
                        const newShippingOptions = window.Rally.data.order.get().shippingOptions;
                        const newSelectedShippingOptionId = window.Rally.data.order.get().selectedShippingOption?.id;
                        const hasShippingOptionsChanged = newSelectedShippingOptionId !== selectedShippingOptionId || initialShippingOptions.find(option => option.id === newSelectedShippingOptionId)?.total !== newShippingOptions.find(option => option.id === newSelectedShippingOptionId)?.total;
                        const qualifiesForFreeShipping = !initialShippingOptions?.find(option => option.total === 0) && newShippingOptions?.some(option => option.total === 0);
                        if (hasShippingOptionsChanged || qualifiesForFreeShipping) {
                            if (!isFullPageOffer) {
                                mainOfferModal.remove();
                            }
                            renderShippingModal({
                                title: qualifiesForFreeShipping ? 'OFFER.FREE_SHIPPING_MODAL_TITLE' : 'OFFER.SHIPPING_UPDATE_MODAL_TITLE',
                                subheading: qualifiesForFreeShipping ? 'OFFER.FREE_SHIPPING_MODAL_INFO' : 'OFFER.SHIPPING_UPDATE_MODAL_INFO',
                                buttonClickHandler: finishFlow
                            });

                            const delay = 15 * 60 * 1000;
                            setTimeout(finishFlow, delay);
                        } else {
                            finishFlow();
                        }
                    });
                }
            }
            if (!isFullPageOffer && data?.height && iframeElement) {
                iframeElement.style.height = `${data.height}px`;
            }
        });
    }

    const handleStyles = () => {
        const addStyles = () => {
            const stylesheet = document.createElement('style');
            stylesheet.type = 'text/css';
            stylesheet.innerHTML = `
                    select.rally-form-control {
                        background-image: none;
                    }
                    .rally-modal-open.rally-full-page-offer .rally-modal-dialog.rally-offer-modal-dialog {
                        max-width: 100vw;
                        max-height: 100vh;
                        height: 100vh;
                        width: 100vw;
                        transform: none;
                        margin: 0;
                        padding: 0;
                    }
                    .rally-modal-open iframe {
                        opacity: 1;
                        transition: opacity 0.25s ease-in 0.2s;
                    }
                    .rally-modal-open.rally-new-offer-loading iframe {
                        opacity: 0;
                        transition: opacity 0.25s ease-in;
                    }
                    .rally-modal-open.rally-full-page-offer iframe {
                        max-width: 100vw;
                        max-height: 100vh;
                        height: 100vh !important;
                        width: 100vw;
                    }
                    .rally-modal-open.rally-full-page-offer .rally-modal-content {
                        border-radius: 0;
                    }
                    .rally-modal-body {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 20px !important;
                    }
                    .rally-d-none {
                        display: none !important;
                    }
                    rally-shipping-options {
                        width: 100%;
                    }
                    .rally-modal-dialog.rally-offer-shipping-modal {
                        max-width: 600px;
                    }
                    .rally-offer-shipping-modal .rally-modal-content {
                        border-radius: 16px;
                        padding: 10px;
                    }
                    .rally-offer-shipping-modal .rally-modal-header {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding-bottom: 10px;
                    }
                    .rally-offer-shipping-modal .rally-modal-header h2 {
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 30px;
                        margin-bottom: 25px;
                    }
                    .rally-offer-shipping-modal .rally-modal-header p {
                        line-height: 22px;
                    }
                    .rally-offer-shipping-modal .rally-modal-header span {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    .rally-modal-dialog.rally-offer-shipping-modal p {
                        margin: 0;
                    }
                    .rally-offer-shipping-modal .rally-option:hover {
                        background: var(--background-color);
                        border: none;
                    }
                    .rally-offer-shipping-modal .rally-btn:hover, .rally-offer-shipping-modal .rally-btn:active, rally-offer-list .rally-btn:hover, rally-offer-list .rally-btn:active{
                        background: var(--primary-color);
                        border: none;
                        color: var(--primary-accent-color);
                    }
                    .rally-offer-list .rally-offer-line-item {
                        padding-bottom: 15px;
                        border-bottom: 1px solid var(--border-color);
                    }
                    .rally-offer-list .rally-offer-line-item.last {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                    .rally-offer-list {
                        margin: 15px 0;
                        background-color: ${window.Rally.platform.orderBumpConfig?.backgroundColor || 'inherit'};
                    }
                    rally-offer-list .rally-offer-list[style="position: relative;"] {
                        z-index: 0
                    }
                    rally-offer-list .rally-offer-list[style="position: relative;"]:has(.show) {
                        z-index: 101;
                    }   
                `;
            if (window.Rally.platform.orderBumpConfig.mobileHookMaxWidth) {
                const mobileHookStyles = `
                        rally-offer-list.rally-order-bump-mobile {
                            display: none;
                        }
                        rally-offer-list.rally-order-bump-desktop {
                            display: inline;
                        }
                        @media (max-width: ${window.Rally.platform.orderBumpConfig.mobileHookMaxWidth}px) {
                            rally-offer-list.rally-order-bump-mobile {
                                display: inline;
                                margin-bottom: 15px;
                            }
                            rally-offer-list.rally-order-bump-desktop {
                                display: none;
                            }
                        }
                    `;
                stylesheet.innerHTML += mobileHookStyles;
            }
            document.head.appendChild(stylesheet);
        }
        addStyles();
    }

    const handleOffersVisibility = () => {
        if (window.Rally.platform.orderBumpConfig?.hideOnElementPresence) {
            function hideOrderBumpsOnPaymentStep() {
                const otherElement = document.querySelector(window.Rally.platform.orderBumpConfig.hideOnElementPresence?.presentElHook);
                const elementsToHide = document.querySelectorAll('rally-offer-list');
                if (otherElement) {
                    elementsToHide.forEach((elementToHide) => elementToHide?.classList?.add('rally-d-none'));
                } else {
                    elementsToHide.forEach((elementToHide) => elementToHide?.classList?.remove('rally-d-none'));
                }
            }

            hideOrderBumpsOnPaymentStep();

            const observer = new MutationObserver(mutations => {
                for (const mutation of mutations) {
                    if (mutation.type === 'childList') {
                        hideOrderBumpsOnPaymentStep();
                    }
                }
            });

            const targetNode = document.querySelector(window.Rally.platform.orderBumpConfig.hideOnElementPresence?.observedElHook);
            const config = { childList: true, subtree: true };
            observer.observe(targetNode, config);
        }
    }


    const handleOrderBumps = () => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    const hasElement = window.Rally.platform.orderBumpConfig.getHookElement();
                    const needsMobileElement = window.Rally.platform.orderBumpConfig.getMobileHookElement;
                    const hasMobileElement = needsMobileElement ? window.Rally.platform.orderBumpConfig.getMobileHookElement() : true;
                    if (hasMobileElement && hasElement && !isRallyOrderBumpsInitialized) {
                        isRallyOrderBumpsInitialized = true;
                        observer.disconnect();
                        addRallyOrderBumps();
                        handleOffersVisibility();
                    }
                }
            });
        });

        const config = { attributes: false, childList: true, characterData: false, subtree: true };
        observer.observe(document.body || document.documentElement, config);
    }

    const addRallyOrderBumps = () => {
        if (window.Rally.events) {
            window.Rally.events.subscribe('offer.accepted', () => RallyCheckoutData.refresh());
        } else {
            document.addEventListener('rally.initiated', () => window.Rally.events.subscribe('offer.accepted', () => RallyCheckoutData.refresh()))
        }
        const orderBumps: { desktop?: HTMLElement, mobile?: HTMLElement } = {};

        addDesktopOrderBump(orderBumps);
        addMobileOrderBump(orderBumps);

        handleOrderBumpVerticalDesign();
        handleOrderBumpMobileHookMaxWidth(orderBumps);
    }

    const addDesktopOrderBump = (orderBumps) => {
        const desktopOrderBump = document.createElement('rally-offer-list');
        desktopOrderBump.classList.add('rally-order-bump-desktop');
        if (window.Rally.platform.orderBumpConfig?.hideOnElementPresence) {
            desktopOrderBump.classList.add('rally-d-none');
        }
        orderBumps.desktop = desktopOrderBump;
        window.Rally.platform.orderBumpConfig.getHookElement().insertAdjacentElement('afterend', desktopOrderBump);
    }

    const addMobileOrderBump = (orderBumps) => {
        if (window.Rally.platform.orderBumpConfig.getMobileHookElement) {
            const mobileOrderBump = document.createElement('rally-offer-list');
            mobileOrderBump.classList.add('rally-order-bump-mobile');
            if (window.Rally.platform.orderBumpConfig?.hideOnElementPresence) {
                mobileOrderBump.classList.add('rally-d-none');
            }
            orderBumps.mobile = mobileOrderBump;
            window.Rally.platform.orderBumpConfig.getMobileHookElement().insertAdjacentElement('afterend', mobileOrderBump);
        }
    }

    const handleOrderBumpVerticalDesign = () => {
        if (window.Rally.platform?.orderBumpConfig?.verticalDesignMaxWidth) {
            const orderBumpMediaQuery = window.matchMedia(`(max-width: ${window.Rally.platform.orderBumpConfig.verticalDesignMaxWidth}px)`);
            const applyVerticalDesign = (e) => {
                const orderBumps = document.querySelectorAll('rally-offer-list');
                orderBumps.forEach(orderBump => orderBump.classList.toggle('rally-offer-vertical-design', e.matches));
            };
            orderBumpMediaQuery.addEventListener('change', applyVerticalDesign);
            if (orderBumpMediaQuery.matches) {
                applyVerticalDesign(orderBumpMediaQuery);
            }
        }
    };

    const handleOrderBumpMobileHookMaxWidth = (orderBumps) => {
        if (window.Rally.platform?.orderBumpConfig?.mobileHookMaxWidth) {
            const orderBumpMediaQuery = window.matchMedia(`(max-width: ${window.Rally.platform.orderBumpConfig.mobileHookMaxWidth}px)`);
            const handleElementVisibility = (e) => {
                if (e.matches) {
                    orderBumps.desktop.remove();
                    if (!document.querySelector('.rally-order-bump-mobile')) {
                        addMobileOrderBump(orderBumps);
                    }
                } else {
                    orderBumps.mobile.remove();
                    if (!document.querySelector('.rally-order-bump-desktop')) {
                        addDesktopOrderBump(orderBumps);
                    }
                }
            };
            orderBumpMediaQuery.addEventListener('change', handleElementVisibility);
            if (orderBumpMediaQuery.matches) {
                handleElementVisibility(orderBumpMediaQuery);
            }
        }
    };

    const handlePostPurchaseOffers = () => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    const hasElement = window.Rally.platform.postPurchaseOfferConfig?.getPlaceOrderElement();
                    if (hasElement && !isRallyPostPurchaseInitialized) {
                        isRallyPostPurchaseInitialized = true
                        observer.disconnect();
                        addRallyPostPurchaseOffers();
                    }
                }
            });
        });

        const config = { attributes: false, childList: true, characterData: false, subtree: true };
        observer.observe(document.body || document.documentElement, config);
    }

    const addRallyPostPurchaseOffers = () => {
        const nativePurchaseButton = window.Rally.platform.postPurchaseOfferConfig?.getPlaceOrderElement();
        const rallyPurchaseButton = document.createElement('button');
        rallyPurchaseButton.id = 'rallyPlaceOrder';
        rallyPurchaseButton.type = 'button';
        rallyPurchaseButton.className = nativePurchaseButton.className;
        rallyPurchaseButton.innerHTML = nativePurchaseButton.innerHTML;
        rallyPurchaseButton.addEventListener('click', (event) => {
            event.stopImmediatePropagation();
            event.stopPropagation();
            if (!isPayNowClickInProgress) {
                isPayNowClickInProgress = true;
                if (localStorage.getItem(`${RallyCheckoutData.id}-hasInitializedPPOFlow`) === 'true') {
                    isCompleteCheckoutInitiated = true;
                    nativePurchaseButton.click();
                    isPayNowClickInProgress = false;
                } else {
                    const handlePostPurchaseFlow = (offers) => {
                        if (offers?.active) {
                            localStorage.setItem(`${RallyCheckoutData.id}-hasInitializedPPOFlow`, 'true');
                            handlePPO(offers.active);
                            window.Rally.data.refresh(() => {
                                initialShippingOptions = window.Rally.data.order.get().shippingOptions;
                                selectedShippingOptionId = window.Rally.data.order.get().selectedShippingOption?.id;
                            });
                        } else {
                            isCompleteCheckoutInitiated = true;
                            nativePurchaseButton.click();
                            isPayNowClickInProgress = false;
                        }
                    }
                    Rally.data.offers.fetch(handlePostPurchaseFlow);
                }
            }
        });
        nativePurchaseButton?.classList.add('rally-d-none');
        nativePurchaseButton?.insertAdjacentElement('afterEnd', rallyPurchaseButton);
    }

    const handleBeforeUnloadEvents = () => {
        window.addEventListener("beforeunload", (e) => {
            if (localStorage.getItem(`${RallyCheckoutData.id}-hasInitializedPPOFlow`) === 'true' && !isCompleteCheckoutInitiated) {
                isCompleteCheckoutInitiated = true;
                e.preventDefault();
                document.cookie = `rallyOrderInitiated=${RallyCheckoutData.id};`;
                const nativePurchaseButton = window.Rally.platform.postPurchaseOfferConfig?.getPlaceOrderElement();
                nativePurchaseButton.click();
                return true;
            } else {
                return false;
            }
        });
        window.addEventListener("visibilitychange", (e) => {
            if (document.visibilityState == 'hidden') {
                if (localStorage.getItem(`${RallyCheckoutData.id}-hasInitializedPPOFlow`) === 'true' && !isCompleteCheckoutInitiated) {
                    isCompleteCheckoutInitiated = true;
                    e.preventDefault();
                    document.cookie = `rallyOrderInitiated=${RallyCheckoutData.id};`;
                    const nativePurchaseButton = window.Rally.platform.postPurchaseOfferConfig?.getPlaceOrderElement();
                    nativePurchaseButton.click();
                    return true;
                } else {
                    return false;
                }
            }
        });
    };

    const renderShippingModal = (modalConfig) => {
        const shippingOptionsConfig = {
            showHeading: false,
            showSubheading: false,
        };
        const ctaButtonConfig = {
            text: {
                button: 'OFFER.SHIPPING_MODAL_CTA'
            },
        };
        const returnToCheckoutText = window.Rally.translate.get('OFFER.SHIPPING_MODAL_RETURN_TO_CHECKOUT');
        const modal = document.createElement('rally-modal');
        modal.setAttribute('config', '{"closableOnBackdropClick": false, "modalDialogClass": "rally-modal-dialog rally-modal-dialog-scrollable rally-offer-shipping-modal"}');
        modal.innerHTML = `
                <div class="rally-modal-header">
                    <h2>${window.Rally.translate.get(modalConfig.title)}</h2>
                    <p>${window.Rally.translate.get(modalConfig.subheading)}<span>${returnToCheckoutText}</span></p>
                </div>
                <div class="rally-modal-body">
                    <rally-shipping-options config='${JSON.stringify(shippingOptionsConfig)}'></rally-shipping-options>
                </div>
                <div class="rally-modal-footer">
                    <rally-button config='${JSON.stringify(ctaButtonConfig)}'></rally-button>
                </div>
                `;
        document.body.appendChild(modal);
        window.Rally.elements.get(document.querySelector('.rally-offer-shipping-modal rally-button')).subscribe('click', () => modalConfig.buttonClickHandler(modal));
        const returnToCheckoutElement = document.querySelector('.rally-offer-shipping-modal .rally-modal-header span');
        returnToCheckoutElement?.addEventListener('click', () => {
            RallyCheckoutData.refresh(() => {
                modal.remove();
                mainOfferModal?.remove();
            });
        });
    };

    return {
        init: () => {
            handleBeforeUnloadEvents();
            handleStyles();
            handleOrderBumps();
            handlePostPurchaseOffers();
        }
    }
}

export const offers = createOffersService();